import Vue from "vue";
import VueYandexMetrika from "vue-yandex-metrika";

if (process.env.VUE_APP_YAMETRIKA_ID) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YAMETRIKA_ID,
    env: process.env.NODE_ENV,
    debug: process.env.VUE_APP_DEBUG === "true",
  });
}

export default {};
