<template>
  <div class="cookie-alert" :class="{ show: showAlert }" role="alert">
    <div class="cookie-alert__container">
      <span class="cookie-alert__icon">🍪</span>
      <div class="cookie-alert__desc">
        Мы используем&nbsp;cookies и&nbsp;рекомендательные технологии для
        улучшения работы сайта. Продолжая использовать этот сайт,
        <a
          href="https://www.spbstu.ru/upload/personal_cookie.pdf"
          target="_blank"
          >Вы соглашаетесь на использование файлов cookie</a
        >.
      </div>
      <v-btn color="primary" class="text-none" @click="agree">
        Принять
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * Show cookies agreement alert
     */
    showAlert() {
      return !this.$store.state.cookies;
    },
  },

  methods: {
    /**
     * Agree to cookies usage
     */
    agree() {
      this.$store.commit("cookiesAgree", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-alert {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transform: translateY(100%);
  transition: all 0.5s ease-out;
  background: #fff;
  width: 100%;
  max-width: 768px;
  border: 1px solid rgba(10, 10, 10, 0.2);
  padding: 0.75rem 1.25rem;
}

.cookie-alert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 1s;
}

.cookie-alert__container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 499px) {
    flex-direction: column;
  }
}

.cookie-alert__icon {
  font-size: 1.5rem;
}

.cookie-alert__desc {
  display: inline-block;
  font-size: 0.84rem;
}
</style>
