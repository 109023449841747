import Vue from "vue";

import http from "./plugins/axios";
import store from "./store";
import router from "./router";
import auth from "./plugins/auth";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import rules from "./plugins/rules";
import yametrika from "./plugins/yametrika";

import App from "./App.vue";

import "./styles/fonts.scss";
import "./styles/general.scss";

new Vue({
  i18n,
  http,
  store,
  router,
  auth,
  vuetify,
  rules,
  yametrika,

  beforeCreate() {
    rules.register(this);
    this.$store.commit("initializeStore");
  },

  render: h => h(App),
}).$mount("#app");
