<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar color="green darken-4" size="38">
          <span class="white--text text-h7">{{ initials }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center pb-0">
        <div class="mx-auto text-center">
          <div class="px-3">
            <h3 class="mt-1">{{ fioShort }}</h3>
            <p class="text-caption mt-1 mb-3">&lt;{{ login }}&gt;</p>
          </div>

          <v-divider></v-divider>

          <router-link class="text-decoration-none" :to="{ name: 'account' }">
            <v-btn depressed text block large>
              <v-icon left>$mdi-account</v-icon>
              {{ $t("account.info") }}
            </v-btn>
          </router-link>
          <v-btn depressed text block large @click="logout">
            <v-icon left>$mdi-logout</v-icon>
            {{ $t("login.logout") }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    initials() {
      // Admin is no one
      if (this.$auth.user().roles.includes("admin"))
        return this.$t("account.admin_initials");

      if (this.fioShort.includes(" "))
        return (
          this.fioShort.substr(this.fioShort.indexOf(" ") + 1, 1) +
          this.fioShort[0]
        );

      return this.fioShort[0];
    },

    fioShort() {
      // Admin is no one
      if (this.$auth.user().roles.includes("admin"))
        return this.$t("account.admin");

      const fio = this.$auth.user().fio;

      const arr = fio.split(" ");
      var fioShort = arr[0];
      for (var i = 1; i < arr.length; i++) {
        if (i === 1) fioShort += " ";
        fioShort += arr[i][0] + ".";
      }

      return fioShort;
    },

    login() {
      return this.$auth.user().login;
    },
  },

  methods: {
    /**
     * Log out of the system
     */
    logout() {
      this.$store.dispatch("auth/logout").then(null, e => {
        this.HandleError(e);
      });
    },
  },
};
</script>
