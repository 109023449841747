export default {
  namespaced: true,

  state: {
    itemsPerPage: {},
  },

  mutations: {
    itemsPerPage(state, { type, value }) {
      state.itemsPerPage[type] = value;
    },
  },

  getters: {
    /**
     * Get values for saving in localStorage
     */
    savingValues: state => {
      return state;
    },
  },
};
