<template>
  <v-app class="app">
    <v-parallax
      class="header"
      height="250"
      :src="require('@/assets/header.png')"
    >
    </v-parallax>

    <v-app-bar absolute dark flat color="transparent">
      <router-link :to="{ name: 'index' }">
        <v-img
          :src="require('@/assets/logo.svg')"
          class="logo flex-grow-0 mr-3"
          height="40"
          width="40"
          contain
        />
      </router-link>

      <v-divider vertical></v-divider>

      <a
        href="//www.spbstu.ru/abit/master/"
        target="_blank"
        rel="noopener noreferrer"
        class="abit-link d-flex ml-3 text-decoration-none text-uppercase text-h6"
      >
        Поступление <span class="ml-1">2024</span>
      </a>

      <v-spacer></v-spacer>

      <slot v-if="showNavigation">
        <v-tabs
          v-if="$vuetify.breakpoint.smAndUp"
          align-with-title
          hide-slider
          optional
        >
          <v-tab exact :to="{ name: 'apps-list' }"
            ><v-icon left>$mdi-file-multiple</v-icon>
            {{ $t("layout.navbar.apps") }}</v-tab
          >
          <v-tab
            v-if="$vuetify.breakpoint.mdAndUp"
            exact
            :to="{ name: 'stats' }"
            ><v-icon left>$mdi-chart-bar</v-icon>
            {{ $t("layout.navbar.stats") }}</v-tab
          >
          <slot v-if="$vuetify.breakpoint.lgAndUp">
            <v-tab
              v-if="$auth.user().roles.includes('admin')"
              exact
              :to="{ name: 'moderators-list' }"
              ><v-icon left>$mdi-account-multiple</v-icon>
              {{ $t("layout.navbar.moderators") }}</v-tab
            >
            <v-tab
              v-if="$auth.user().roles.includes('admin')"
              exact
              :to="{ name: 'settings' }"
              ><v-icon left>$mdi-cog</v-icon>
              {{ $t("layout.navbar.settings") }}</v-tab
            >
          </slot>
        </v-tabs>

        <NavButton />
        <AccountButton />
      </slot>
    </v-app-bar>

    <v-main v-if="loaded" style="margin-top: -150px; margin-bottom: 100px;">
      <router-view />
    </v-main>

    <v-footer app absolute style="background:#00392a" dark padless>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div class="font-weight-bold">
              {{ new Date().getFullYear() }} —
              {{ $t("layout.footer.title") }}
            </div>
            <a href="//www.spbstu.ru/upload/personal_data_policy.pdf">{{
              $t("layout.footer.confpolicy")
            }}</a>
            <div class="muted--text">{{ $t("layout.footer.copyright") }}</div>
            <div>
              <a class="d-inline-block mr-2" href="//www.spbstu.ru/">
                <img :src="require('@/assets/logo_grey.svg')" width="30" />
              </a>
              <!-- <a class="d-inline-block mr-2" href="//rccedu.spbstu.ru/">
                <img :src="require('@/assets/rccedu_grey.svg')" width="30" />
              </a> -->
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="font-weight-bold">
              {{ $t("layout.footer.contacts") }}
            </div>
            <a href="//goo.gl/maps/LGjt2hBHsbq"
              ><v-icon small class="mr-1">$mdi-map</v-icon
              >{{ $t("layout.footer.address") }}</a
            >
            <a href="tel:+78125527765"
              ><v-icon small class="mr-1">$mdi-phone</v-icon
              >{{ $t("layout.footer.phone") }}</a
            >
            <a href="mailto:enter@spbstu.ru"
              ><v-icon small class="mr-1">$mdi-email</v-icon
              >{{ $t("layout.footer.email") }}</a
            >
            <div style="color:#00392a; position:absolute; right:0;bottom:0;">
              ©LIK
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <!-- Cookies -->
    <cookies-agreement></cookies-agreement>

    <!-- Helper global modules -->
    <confirm ref="confirm"></confirm>
    <info ref="info"></info>
    <alert ref="alert"></alert>
  </v-app>
</template>

<script>
import alert from "@/components/AlertPanel";
import confirm from "@/components/ConfirmDialog";
import info from "@/components/InfoDialog";
import axios from "axios";
import AccountButton from "./components/AccountButton.vue";
import NavButton from "./components/NavButton.vue";
import CookiesAgreement from "./components/CookiesAgreement.vue";

export default {
  name: "App",

  components: {
    confirm,
    info,
    alert,
    AccountButton,
    NavButton,
    CookiesAgreement,
  },

  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    showNavigation() {
      return this.$auth.ready() && !!this.$auth.user();
    },
  },

  async mounted() {
    // Global dialogs
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$info = this.$refs.info.open;

    // Global alerts
    this.$root.$alert = this.$refs.alert.add;
    this.$router.beforeEach((to, from, next) => {
      this.$refs.alert?.clear();
      next();
    });

    // Global interceptor for net error
    axios.interceptors.response.use(undefined, error => {
      if (error.message === "Network Error") {
        this.$root.$alert("error", this.$t("error.net.desc"));
      }
      return Promise.reject(error);
    });

    // Load pars
    try {
      const res = await this.$http.get("/pars");
      this.$store.commit("pars/init", res.data);
      this.loaded = true;
    } catch (e) {
      this.HandleError(e);
    }
  },
};
</script>

<style lang="scss">
.app {
  .theme--light.v-application {
    background: #e4f9e8;
  }

  .alert-panel {
    position: fixed;
    right: 0;
    top: 10px;
    width: 500px;
    z-index: 300;

    @media only screen and (max-width: 499px) {
      width: 100%;
    }
  }

  .v-parallax.header {
    .logo {
      position: absolute;
      right: 30px;
      top: 30px;
      max-height: 90px;
      max-width: 250px;
    }

    @media only screen and (max-width: 399px) {
      max-height: 220px;

      .logo {
        top: 15px;
        max-height: 60px;
        max-width: 180px;
      }
    }
  }

  .abit-link {
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.15s ease-in;
    user-select: none;
    text-shadow: 0 0 #00392a;

    span {
      color: rgb(0, 57, 42);
    }

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 399px) {
    .abit-link {
      font-size: 1rem !important;
    }
  }

  .v-app-bar {
    .v-tabs-bar__content {
      justify-content: flex-end;
    }
  }

  .v-footer {
    font-size: 0.8rem;
    font-weight: 300;

    .muted--text {
      color: #999;
    }

    .row {
      div {
        a,
        div {
          margin: 10px 0px;
        }
      }
    }

    a {
      display: block;
      text-decoration: none;
      color: #ccc;
      font-weight: 400;
    }

    a:hover,
    a:active {
      color: white;
    }
  }
}
</style>
