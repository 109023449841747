import Vue from "vue";

class Rules {
  constructor(vue) {
    this.vue = vue;
  }

  required = v => !!v || this.vue.$t("frm.error.required");

  fio = v =>
    !v || /^[a-zA-Zа-яА-ЯёЁ\-_ ]+$/.test(v) || this.vue.$t("frm.error.fio");

  email = v => !v || /^.+@.+\..+$/.test(v) || this.vue.$t("frm.error.email");

  len(val) {
    return v =>
      !v || v.length <= val || this.vue.$t("frm.error.long", { length: val });
  }

  lenBIG = this.len(1024);

  sizeMax(max) {
    return v =>
      !v || v.length <= max || this.vue.$t("frm.error.size_max", { max });
  }

  password = v =>
    !v ||
    (v.length >= 3 && v.length <= 16) ||
    this.vue.$t("frm.error.password");

  passSN = v =>
    !v || /^\d{4} \d{6}$/.test(v) || this.vue.$t("frm.error.pass_sn");

  snils = v =>
    !v || /^\d{3}-\d{3}-\d{3} \d{2}$/.test(v) || this.vue.$t("frm.error.snils");
}

export default {
  register(vue) {
    Vue.prototype.$rules = new Rules(vue);
  },
};
