const fileObj = file => {
  if (!file) return null;

  const { lastModified, lastModifiedDate, name, size, type } = file;
  return { lastModified, lastModifiedDate, name, size, type };
};

const getDefaultState = () => {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.VUE_APP_DEBUG === "true"
  ) {
    return {
      _id: null,
      key: null,

      // Stage1
      fio: "AAAA",
      email: "7still@list.ru",
      bdate: "1993-09-03",
      videobc: "ya.ru",
      paths: [{ _no: 1, institute: "8", type: "1136" }],

      // Stage2
      organization: "Test",
      grade: "stud_2",
      grade_scan: null,
      app_scan: null,

      // Stage3
      papers: [],

      // Stage4
      achs_base: [],

      // Stage5
      achs_spec: [],
    };
  }

  return {
    _id: null,
    key: null,

    // Stage1
    fio: null,
    email: null,
    bdate: null,
    videobc: null,
    paths: [{ _no: 1, institute: null, type: null }],

    // Stage2
    organization: null,
    grade: null,
    grade_scan: null,
    app_scan: null,

    // Stage3
    papers: [],

    // Stage4
    achs_base: [],

    // Stage5
    achs_spec: [],
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    fieldUpdate(state, value) {
      state[value.name] = value.value;
    },
    fileUpdate(state, value) {
      state[value.name] = fileObj(value.value);
    },
    arrayAdd(state, value) {
      var array = state[value.name];
      var no = 1;
      if (array.length) no += array[array.length - 1]._no;

      state[value.name].push({ _no: no, ...value.value });
    },
    arrayRemove(state, value) {
      state[value.name].splice(value.value, 1);
    },
    arrayUpdate(state, value) {
      state[value.name][value.no][value.field] = value.value;
    },
    arrayFileUpdate(state, value) {
      state[value.name][value.no][value.field] = fileObj(value.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    updateState(state, value) {
      Object.assign(state, value);
    },
  },

  getters: {
    /**
     * Get values for saving in localStorage
     */
    savingValues: state => {
      return state;
    },
  },
};
