import Vue from "vue";
import VueRouter from "vue-router";
import { publicPath } from "~/vue.config";

Vue.use(VueRouter);

function loadView(view) {
  return () => import(`../pages/${view}.vue`);
}

Vue.router = new VueRouter({
  hashbang: false,
  mode: "history",
  base: publicPath,
  routes: [
    // Home page
    {
      path: "/",
      name: "index",
      component: loadView("Index"),
    },

    // News
    {
      path: "/news",
      name: "news",
      component: loadView("News"),
    },

    // Grants
    {
      path: "/grants",
      name: "grants",
      component: loadView("Grants"),
    },

    // Register application
    {
      path: "/register",
      name: "register",
      component: loadView("form/Form"),
    },

    {
      path: "/editing/:_id",
      name: "editing",
      component: loadView("form/Form"),
    },

    // Auth-block
    {
      path: "/login",
      name: "auth-login",
      component: loadView("auth/Login"),
      meta: {
        auth: {
          roles: false,
          redirect: "/apps",
        },
      },
    },

    {
      path: "/account",
      name: "account",
      component: loadView("auth/Account"),
      meta: { auth: true },
    },

    // Applications management (authorized only)
    {
      path: "/apps",
      name: "apps-list",
      component: loadView("apps/List"),
      meta: { auth: true },
    },

    {
      path: "/apps/:_id/:path",
      name: "apps-view",
      component: loadView("apps/View"),
      meta: { auth: true },
    },

    // Applications statistics
    {
      path: "/stats",
      name: "stats",
      component: loadView("Stats"),
      meta: { auth: true },
    },

    // Moderator staff management (admin only)
    {
      path: "/moderators",
      name: "moderators-list",
      component: loadView("Moderators"),
      meta: {
        auth: {
          roles: ["admin"],
          redirect: "/apps",
        },
      },
    },

    // Settings (admin only)
    {
      path: "/settings",
      name: "settings",
      component: loadView("Settings"),
      meta: {
        auth: {
          roles: ["admin"],
          redirect: "/apps",
        },
      },
    },

    // Error-pages
    {
      path: "/",
      component: loadView("error/Index"),
      children: [
        {
          path: "/500",
          name: "error-500",
          component: loadView("error/500"),
        },
        {
          path: "/503",
          name: "error-503",
          component: loadView("error/503"),
        },
        {
          path: "*",
          name: "error-404",
          component: loadView("error/404"),
        },
      ],
    },
  ],
});

export default Vue.router;
