import Vue from "vue";

export default {
  namespaced: true,

  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },

    refresh(data) {
      return Vue.auth.refresh(data);
    },

    // eslint-disable-next-line
    login(ctx, data) {
      data = data || {};

      return new Promise((resolve, reject) => {
        var redirectObj = Vue.auth.redirect();

        Vue.auth
          .login({
            url: "auth/login",
            data: data.data,
            staySignedIn: data.staySignedIn,
            fetchUser: data.fetchUser,
            redirect: {
              path: redirectObj ? redirectObj.from.path : "/apps",
            },
          })
          .then(resolve, reject);
      });
    },

    register(ctx, data) {
      data = data || {};

      return new Promise((resolve, reject) => {
        Vue.auth
          .register({
            url: "auth/register",
            data: data.data,
            autoLogin: false,
          })
          .then(() => {
            if (data.autoLogin) {
              ctx.dispatch("login", data).then(resolve, reject);
            }
          }, reject);
      });
    },

    logout() {
      return Vue.auth.logout({
        redirect: {
          path: "/login",
        },
      });
    },
  },

  getters: {
    user() {
      return Vue.auth.user();
    },

    impersonating() {
      return Vue.auth.impersonating();
    },
  },
};
