import Vue from "vue";
import Vuetify, { VAutocomplete, VSelect } from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

import {
  mdiFileMultiple,
  mdiChartBar,
  mdiAccountMultiple,
  mdiCog,
  mdiMap,
  mdiPhone,
  mdiEmail,
  mdiAccount,
  mdiLogout,
  mdiDatabase,
  mdiMedal,
  mdiDelete,
  mdiClose,
  mdiCheck,
  mdiCalendar,
  mdiClock,
  mdiFile,
  mdiPaperclip,
  mdiBookEducation,
  mdiFormatTitle,
  mdiBookOpenVariant,
  mdiWeb,
  mdiPlusBox,
  mdiMagnify,
  mdiPencil,
  mdiCloudDownload,
  mdiPlay,
  mdiDownload,
  mdiArchiveArrowDown,
  mdiLockOpen,
  mdiCalendarCheck,
  mdiLock,
  mdiCheckCircle,
  mdiCancel,
  mdiAlertCircle,
  mdiFileDownload,
  mdiEmailOpen,
  mdiCloseCircle,
  mdiCheckAll,
  mdiArrowULeftTop,
  mdiSchool,
  mdiContentSave,
  mdiContentSaveOff,
  mdiKeyVariant,
  mdiKey,
  mdiEye,
  mdiEyeOff,
  mdiHome,
  mdiRefresh,
  mdiArrowLeft,
  mdiArrowRight,
  mdiInformation,
  mdiPlus,
} from "@mdi/js";

Vue.use(Vuetify, {
  components: {
    VAutocomplete,
    VSelect,
  },
  directives: {
    Ripple,
  },
});

import ru from "vuetify/es5/locale/ru";

ru.datePicker.itemsSelected = "период";

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: "#37b34a",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      "mdi-file-multiple": mdiFileMultiple,
      "mdi-chart-bar": mdiChartBar,
      "mdi-account-multiple": mdiAccountMultiple,
      "mdi-cog": mdiCog,
      "mdi-map": mdiMap,
      "mdi-phone": mdiPhone,
      "mdi-email": mdiEmail,
      "mdi-account": mdiAccount,
      "mdi-logout": mdiLogout,
      "mdi-database": mdiDatabase,
      "mdi-medal": mdiMedal,
      "mdi-delete": mdiDelete,
      "mdi-close": mdiClose,
      "mdi-check": mdiCheck,
      "mdi-calendar": mdiCalendar,
      "mdi-clock": mdiClock,
      "mdi-file": mdiFile,
      "mdi-paperclip": mdiPaperclip,
      "mdi-book-education": mdiBookEducation,
      "mdi-format-title": mdiFormatTitle,
      "mdi-book-open-variant": mdiBookOpenVariant,
      "mdi-web": mdiWeb,
      "mdi-plus-box": mdiPlusBox,
      "mdi-magnify": mdiMagnify,
      "mdi-pencil": mdiPencil,
      "mdi-cloud-download": mdiCloudDownload,
      "mdi-play": mdiPlay,
      "mdi-download": mdiDownload,
      "mdi-archive-arrow-down": mdiArchiveArrowDown,
      "mdi-lock-open": mdiLockOpen,
      "mdi-key": mdiKey,
      "mdi-calendar-check": mdiCalendarCheck,
      "mdi-lock": mdiLock,
      "mdi-check-circle": mdiCheckCircle,
      "mdi-cancel": mdiCancel,
      "mdi-alert-circle": mdiAlertCircle,
      "mdi-file-download": mdiFileDownload,
      "mdi-email-open": mdiEmailOpen,
      "mdi-close-circle": mdiCloseCircle,
      "mdi-check-all": mdiCheckAll,
      "mdi-arrow-u-left-top": mdiArrowULeftTop,
      "mdi-school": mdiSchool,
      "mdi-content-save": mdiContentSave,
      "mdi-content-save-off": mdiContentSaveOff,
      "mdi-key-variant": mdiKeyVariant,
      "mdi-eye": mdiEye,
      "mdi-eye-off": mdiEyeOff,
      "mdi-home": mdiHome,
      "mdi-refresh": mdiRefresh,
      "mdi-arrow-left": mdiArrowLeft,
      "mdi-arrow-right": mdiArrowRight,
      "mdi-information": mdiInformation,
      "mdi-plus": mdiPlus,
    },
  },
});
