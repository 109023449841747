<template>
  <div class="alert-panel">
    <slot v-for="n in alerts.length">
      <v-alert
        v-model="alerts[n - 1].show"
        tile
        :type="alerts[n - 1].type"
        dismissible
        elevation="10"
        class="mb-0"
        @input="close(alerts[n - 1])"
      >
        {{ alerts[n - 1].text }}
      </v-alert>
    </slot>
  </div>
</template>

<script>
export default {
  data: () => ({
    alerts: [],
  }),
  methods: {
    // Add alert
    add(type, text, options) {
      var alert = { type, text, options, show: false };
      this.alerts.push(alert);

      // Invoke animation
      setTimeout(() => {
        alert.show = true;
      }, 0);

      // Autoclose
      setTimeout(() => {
        this.close(alert);
      }, 3 * 1000);
    },

    // Close alert and remove from data
    close(alert) {
      var index = this.alerts.indexOf(alert);
      if (index >= 0) this.alerts.splice(index, 1);
    },

    // Clear all alerts
    clear() {
      this.alerts = [];
    },
  },
};
</script>

<style lang="scss">
.alert-panel {
  .v-alert {
    &.error {
      background-color: rgba(255, 82, 82, 0.95) !important;
    }

    &.success {
      background-color: rgba(0, 85, 28, 0.95) !important;
    }

    .v-icon {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
</style>
