import Vue from "vue";

import auth from "@websanova/vue-auth/src/index.js";
import authBearer from "@websanova/vue-auth/drivers/auth/bearer.js";
import httpAxios from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import routerVueRouter from "@websanova/vue-auth/drivers/router/vue-router.2.x.js";

Vue.use(auth, {
  auth: authBearer,
  http: httpAxios,
  router: routerVueRouter,
  refreshData: {
    enabled: true,
  },
  fetchData: {
    enabled: true,
  },
});

// Global interceptor
Vue.axios.interceptors.response.use(undefined, error => {
  // Request was canceled
  if (Vue.axios.isCancel(error)) return;

  // Logout if server didn't respond on refresh
  if (
    // error.message === "Network Error" &&
    error.config?.url === "auth/refresh"
  ) {
    Vue.auth.logout();
  } else return Promise.reject(error);
});

export default {};
