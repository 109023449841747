module.exports = {
  publicPath: process.env.VUE_APP_PUBLIC_PATH,

  devServer: {
    port: "3000",
    disableHostCheck: true,
  },
  configureWebpack: {
    resolve: {
      alias: {
        "~": __dirname,
      },
    },
  },
  pluginOptions: {
    i18n: {
      locale: "ru",
      fallbackLocale: "ru",
      localeDir: "locales",
      enableInSFC: false,
    },
    compression: {
      gzip: {
        filename: "[path].gz[query]",
        algorithm: "gzip",
        include: /\.(js|css|html|svg|json)(\?.*)?$/i,
        minRatio: 0.8,
      },
    },
  },
  chainWebpack: config => {
    config.performance
      .maxEntrypointSize(2 * 1000 * 1024)
      .maxAssetSize(600 * 1024);
    config.plugins.delete("prefetch");
  },
  transpileDependencies: ["vuetify"],
};
