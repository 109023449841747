export default {
  namespaced: true,

  state: {
    institutes: [],
    paths: [],
    achs_base: [],
    achs_spec: [],
    isOpen: false,
  },

  mutations: {
    init(state, value) {
      state.institutes = value.institutes;
      state.paths = value.paths;
      state.achs_base = value.achs_base;
      state.achs_spec = value.achs_spec;
      state.isOpen = value.isOpen;
    },
  },
};
