<template>
  <v-menu
    v-if="
      $vuetify.breakpoint.smAndDown || ($vuetify.breakpoint.md && this.isAdmin)
    "
    bottom
    min-width="200px"
    rounded
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center py-0">
        <div class="mx-auto text-center">
          <router-link
            v-if="$vuetify.breakpoint.xs"
            class="text-decoration-none"
            :to="{ name: 'apps-list' }"
          >
            <v-btn depressed text block large>
              <v-icon left>$mdi-file-multiple</v-icon>
              {{ $t("layout.navbar.apps") }}
            </v-btn>
          </router-link>

          <router-link
            v-if="$vuetify.breakpoint.smAndDown"
            class="text-decoration-none"
            :to="{ name: 'stats' }"
          >
            <v-btn depressed text block large>
              <v-icon left>$mdi-chart-bar</v-icon>
              {{ $t("layout.navbar.stats") }}
            </v-btn>
          </router-link>

          <router-link
            v-if="this.isAdmin"
            class="text-decoration-none"
            :to="{ name: 'moderators-list' }"
          >
            <v-btn depressed text block large>
              <v-icon left>$mdi-account-multiple</v-icon>
              {{ $t("layout.navbar.moderators") }}
            </v-btn>
          </router-link>

          <router-link
            v-if="this.isAdmin"
            class="text-decoration-none"
            :to="{ name: 'settings' }"
          >
            <v-btn depressed text block large>
              <v-icon left>$mdi-cog</v-icon>
              {{ $t("layout.navbar.settings") }}
            </v-btn>
          </router-link>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    /**
     * Check if current user is Admin
     */
    isAdmin() {
      return this.$auth.user().roles.includes("admin");
    },

    initials() {
      // Admin is no one
      if (this.isAdmin) return this.$t("account.admin_initials");

      if (this.fioShort.includes(" "))
        return (
          this.fioShort.substr(this.fioShort.indexOf(" ") + 1, 1) +
          this.fioShort[0]
        );

      return this.fioShort[0];
    },

    fioShort() {
      // Admin is no one
      if (this.isAdmin) return this.$t("account.admin");

      const fio = this.$auth.user().fio;

      const arr = fio.split(" ");
      var fioShort = arr[0];
      for (var i = 1; i < arr.length; i++) {
        if (i === 1) fioShort += " ";
        fioShort += arr[i][0] + ".";
      }

      return fioShort;
    },

    login() {
      return this.$auth.user().login;
    },
  },

  methods: {
    /**
     * Log out of the system
     */
    logout() {
      this.$store.dispatch("auth/logout").then(null, e => {
        this.HandleError(e);
      });
    },
  },
};
</script>
