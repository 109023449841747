const getDefaultState = () => {
  return {
    maxstage: 1,
    curstage: 1,
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    maxstage(state, value) {
      state.maxstage = value;
    },
    curstage(state, value) {
      state.curstage = value;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  getters: {
    /**
     * Get values for saving in localStorage
     */
    savingValues: state => {
      return state;
    },
  },
};
