import Vue from "vue";
import Vuex from "vuex";

import { version } from "~/package.json";
import auth from "./auth.js";
import portfolio from "./portfolio.js";
import form from "./form.js";
import pars from "./pars.js";
import ui from "./ui.js";

Vue.use(Vuex);

const storeStorage = sessionStorage;

const store = new Vuex.Store({
  state: {
    version: "",
    cookies: false,
  },

  mutations: {
    /**
     * Set agreements status
     * @param {*} state 
     */
    cookiesAgree(state, value) {
      state.cookies = value;
    },

    /**
     * Load store from storeStorage
     * @param {*} state
     */
    initializeStore(state) {
      if (localStorage.getItem("global")) {
        let global = JSON.parse(localStorage.getItem("global"));
        // Check the project version
        if (global.version === version) {
          this.replaceState(Object.assign(state, global));
        } else {
          state.version = version;
        }
      }

      if (storeStorage.getItem("store")) {
        let store = JSON.parse(storeStorage.getItem("store"));
        // Check the project version
        if (store.version === version) {
          this.replaceState(Object.assign(state, store));
        } else {
          state.version = version;
        }
      }
    },
  },

  modules: {
    auth,
    portfolioNew: portfolio,
    portfolioEdit: portfolio,
    formNew: form,
    formEdit: form,
    pars,
    ui,
  },

  strict: process.env.NODE_ENV !== "production",
});

/**
 * Save state updates to storeStorage
 */
store.subscribe((mutation, state) => {
  // Save global state
  const globalState = {
    version: state.version,
    cookies: state.cookies,
    ui: store.getters["ui/savingValues"],
  };

  localStorage.setItem("global", JSON.stringify(globalState));

  // Save only required local values
  const savingState = {
    version: state.version,
    portfolioNew: store.getters["portfolioNew/savingValues"],
    portfolioEdit: store.getters["portfolioEdit/savingValues"],
    formNew: store.getters["formNew/savingValues"],
    formEdit: store.getters["formEdit/savingValues"],
  };

  storeStorage.setItem("store", JSON.stringify(savingState));
});

export default store;
