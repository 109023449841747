import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(VueAxios, axios);

Vue.mixin({
  methods: {
    HandleError(e, errorPage = false) {
      // Skip cancel error (request was stopped intentionally)
      if (axios.isCancel(e)) return;

      // Skip network error (handled in App.vue)
      if (e.message === "Network Error") return;

      const statusCode = e.response?.status;
      switch (statusCode) {
        case 404:
          if (errorPage) Vue.router.replace({ path: "/404" });
          else {
            this.$root.$alert("error", this.$t("error.404.res"));
          }
          break;

        case 503:
          if (errorPage) Vue.router.replace({ path: "/503" });
          else {
            this.$root.$alert("error", this.$t("error.503.desc"));
          }
          break;

        default:
          if (errorPage) Vue.router.replace({ path: "/500" });
          else {
            this.$root.$alert("error", this.$t("error.500.desc"));
          }
          break;
      }
    },
  },
});

/**
 * Check error match by code and message
 * @param {*} e
 * @returns
 */
const matchError = e => {
  return (status, message = null) =>
    e.response?.status === status && (!message || e.response?.data === message);
};

// Global interceptor
axios.interceptors.response.use(undefined, error => {
  error.match = matchError(error);
  return Promise.reject(error);
});

/**
 * Parse filename from headers
 */
function parseFilename(res, options) {
  let filename = options.prefix || "";
  const contentDisposition =
    res.headers.get?.("Content-Disposition") ||
    res.headers["content-disposition"];

  if (options.filename) {
    filename += options.filename;
  } else if (contentDisposition) {
    filename += decodeURI(contentDisposition.split("filename=")[1]);
  }

  return filename;
}

import { saveAs } from "file-saver";
/**
 * Global file downloader
 * @param {*} url
 * @param {*} data
 * @param {*} options {method, responseType, type, filename, prefix}
 */
axios.download = async (
  url,
  data = null,
  options = null,
  onDownloadProgress = null
) => {
  var defOptions = {
    method: "GET",
    responseType: "blob",
    type: "application/octet-stream",
  };
  if (data) defOptions.method = "POST";
  Object.assign(defOptions, options);

  const res = await axios({
    url,
    method: defOptions.method,
    data,
    responseType: defOptions.responseType,
    onDownloadProgress,
  });

  let blob = new Blob([res.data], { type: defOptions.type });
  const filename = parseFilename(res, defOptions);
  saveAs(blob, filename);
};

import streamSaver from "streamsaver";
streamSaver.mitm = "/mitm.html";

/**
 * Global stream file downloader
 * @param {*} url
 * @param {*} data
 * @param {*} options {method, filename, prefix}
 */
axios.downloadStream = async (
  url,
  data = null,
  options = null,
  onDownloadProgress = null
) => {
  let defOptions = {
    method: "GET",
  };
  if (data) defOptions.method = "POST";
  Object.assign(defOptions, options);

  // Fetch initial data
  const token = localStorage.getItem("auth_token_default");
  const res = await fetch(`${axios.defaults.baseURL}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: defOptions.method,
    body: data ? JSON.stringify(data) : null,
  });

  // Identify file name
  const filename = parseFilename(res, defOptions);

  // Calculate progress
  const total = +res.headers.get("Content-Length");
  let loaded = 0;
  // eslint-disable-next-line
  const progress = new TransformStream({
    transform(chunk, controller) {
      if (onDownloadProgress) {
        loaded += chunk.length;
        onDownloadProgress({ loaded, total });
      }
      controller.enqueue(chunk);
    },
  });

  // Download via stream
  const fileStream = streamSaver.createWriteStream(filename);
  return res.body
    .pipeThrough(progress)
    .pipeTo(fileStream)
    .then(null, e => {
      // Skip empty error when download is stopped
      if (!e) return;

      return Promise.reject(e);
    });
};

export default {
  root: process.env.VUE_APP_API_URL,
};
